<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="mt-5">
    <v-form @submit.prevent="handleSubmit(onSignin)">
      <v-alert type="error" class="mx-auto mb-3" max-width="450" v-if="error">{{ error.message }}</v-alert>
      <v-card class="mx-auto pa-3" max-width="450" elevation="8" light>
        <v-card-title class="justify-center">Veranstalter Login</v-card-title>
        <v-card-text class="pb-0 pt-3 px-3">
          <ValidationProvider name="E-Mail" rules="required|email" v-slot="{ errors }">
            <v-text-field label="E-Mail" type="email" v-model="email" :error-messages="errors" />
          </ValidationProvider>
          <ValidationProvider name="Passwort" rules="required" v-slot="{ errors }">
            <v-text-field
              label="Passwort"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-card-text>
        <v-card-actions class="pt-0 pb-3 px-3">
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn type="submit" block rounded large color="primary" :loading="loading" :disabled="loading">Einloggen</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col sm="12" class="text-sm-right">
                  <router-link to="/auth/forgot">Passwort vergessen?</router-link>
                </v-col>
                <v-col cols="auto" sm="12" class="text-right">
                  <router-link to="/auth/register">Registrieren</router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Signin',
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    email: '',
    password: '',
    showPassword: false,
  }),

  computed: {
    ...mapGetters({
      loading: 'loading/get',
      error: 'error/get',
    }),
  },

  methods: {
    onSignin() {
      this.$store.dispatch('auth/signIn', {
        email: this.email,
        password: this.password,
      });
    },
  },

  destroyed() {
    this.$store.commit('error/clear');
  },
};
</script>
