<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="mt-5">
    <v-form @submit.prevent="handleSubmit(onSubmit)">
      <v-alert type="error" class="mx-auto mb-3" max-width="450" v-if="error">{{ error.message }}</v-alert>
      <v-card class="mx-auto pa-3" max-width="450" elevation="8" light>
        <v-card-title class="justify-center" v-if="!success">Passwort vergessen?</v-card-title>
        <v-card-title class="justify-center" v-else>E-Mail wurde gesendet</v-card-title>
        <v-card-text class="pb-0 pt-3 px-3" v-if="!success">
          <p>Nur keine Panik! Geben Sie einfach die mit Ihrem Konto verbundene E-Mail-Adresse unten ein, und wir senden Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Passworts.</p>
          <p>Ein Elefant vergisst nie etwas. Für alle anderen gibt es die Möglichkeit, das Passwort zurückzusetzen.</p>
        </v-card-text>
        <v-card-text class="pb-0 pt-3 px-3" v-else>
          <p>Wir haben dir einen Link per Mail geschickt, mit dem du dein Passwort zurücksetzen kannst.</p>
        </v-card-text>
        <v-card-text class="py-0 px-3" v-if="!success">
          <ValidationProvider name="E-Mail" rules="required|email" v-slot="{ errors }">
            <v-text-field label="E-Mail" type="email" v-model="email" :error-messages="errors" />
          </ValidationProvider>
        </v-card-text>
        <v-card-actions class="pt-0 pb-3 px-3">
          <v-row align="center">
            <v-col cols="12" sm="6" v-if="!success">
              <v-btn type="submit" block rounded large color="primary" :loading="loading" :disabled="loading">Zurücksetzen</v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-right">
              <router-link to="/auth/login">Zurück zum Login</router-link>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Forgot',
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    email: '',
    success: false,
  }),

  computed: {
    ...mapGetters({
      loading: 'loading/get',
      error: 'error/get',
    }),
  },

  methods: {
    onSubmit() {
      this.$store.dispatch('auth/forgot', { email: this.email }).then(() => {
        if (!this.error) {
          this.success = true;
        }
      });
    },
  },

  destroyed() {
    this.$store.commit('error/clear');
  },
};
</script>
