<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="mt-5">
    <v-form @submit.prevent="handleSubmit(onSignin)">
      <v-alert type="error" class="mx-auto mb-3" max-width="450" v-if="error">{{ error.message }}</v-alert>
      <v-card class="mx-auto pa-3" max-width="450" elevation="8" light>
        <v-card-title class="justify-center" v-if="!success">Als Veranstalter registrieren</v-card-title>
        <v-card-title class="justify-center" v-else>Registrierung erfolgreich abgeschlossen</v-card-title>
        <v-card-text class="pb-0 pt-3 px-3" v-if="!success">
          <ValidationProvider name="E-Mail" rules="required|email" slim v-slot="{ errors }">
            <v-text-field label="E-Mail" type="email" v-model="email" :error-messages="errors" />
          </ValidationProvider>
          <ValidationProvider name="Passwort" rules="required|min:6" slim v-slot="{ errors }">
            <v-text-field
              label="Passwort"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider name="Firmen-/Vereinsname" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Firmen-/Vereinsname" v-model="name" :error-messages="errors" class="mt-5" />
          </ValidationProvider>
          <v-alert border="left" colored-border type="info" dense>
            Bitte gib deine Postadresse an. In deinem Konto kannst du sie als Veranstaltungsort auswählen, weitere Adressen ergänzen und wirst besser gefunden.
          </v-alert>
          <ValidationProvider name="Strasse und Hausnummer" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Strasse und Hausnummer" v-model="address" :error-messages="errors" />
          </ValidationProvider>
          <v-row class="fix-row-space">
            <v-col cols="4">
              <ValidationProvider name="PLZ" rules="required|numeric" slim v-slot="{ errors }">
                <v-text-field label="PLZ" v-model="zip" :error-messages="errors" />
              </ValidationProvider>
            </v-col>
            <v-col cols="8">
              <ValidationProvider name="Ort" rules="required" slim v-slot="{ errors }">
                <v-text-field label="Ort" v-model="city" :error-messages="errors" />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pb-0 pt-3 px-3 text-center" v-else>
          <p>Dein wow-wow Konto ist ab jetzt einsatzbereit! Zeig allen dein Kultur- und Freizeitangebot für Kinder von 0-12 Jahre!</p>
          <p>
            <v-btn to="/auth/login" color="primary">Und los!</v-btn>
          </p>
        </v-card-text>
        <v-card-actions class="pt-0 pb-3 px-3" v-if="!success">
          <v-row align="center">
            <v-col cols="12" sm="6">
              <v-btn type="submit" block rounded large color="primary" :loading="loading" :disabled="loading">Registrieren</v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-right">
              <router-link to="/auth/login">Zurück zum Login</router-link>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Signup',
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    name: '',
    address: '',
    zip: '',
    city: '',
    email: '',
    password: '',
    showPassword: false,
    success: false,
  }),

  computed: {
    ...mapGetters({
      loading: 'loading/get',
      error: 'error/get',
    }),
  },

  methods: {
    onSignin() {
      this.$store.dispatch('auth/signUp', {
        name: this.name,
        address: this.address,
        zip: this.zip,
        city: this.city,
        email: this.email,
        password: this.password,
      }).then(() => {
        if (!this.error) {
          this.success = true;
        }
      });
    },
  },

  destroyed() {
    this.$store.commit('error/clear');
  },
};
</script>

<style lang="scss" scoped>
.fix-row-space {
  margin-top: -12px;
}
</style>
