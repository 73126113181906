<template>
  <ResetPassword v-if="isResetPassword" />
</template>

<script>
export default {
  name: 'Action',

  components: {
    ResetPassword: () => import(/* webpackChunkName: "auth" */ '@/components/scoped/auth/ResetPassword.vue'),
  },

  computed: {
    isResetPassword() {
      return this.$route.query && this.$route.query.mode === 'resetPassword';
    },
  },
};
</script>
