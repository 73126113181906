<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="mt-5">
    <v-form @submit.prevent="handleSubmit(onSubmit)">
      <v-alert type="error" class="mx-auto mb-3" max-width="450" v-if="error">{{ error.message }}</v-alert>
      <v-card class="mx-auto pa-3" max-width="450" elevation="8" light>
        <v-card-title class="justify-center" v-if="!success">Neues Passwort festlegen</v-card-title>
        <v-card-title class="justify-center" v-else>Passwort erneuert</v-card-title>
        <v-card-text class="py-0 px-3" v-if="!success">
          <p>Gib ein neues Passwort ein, um dich wieder einloggen zu können</p>
          <ValidationProvider name="Passwort" rules="required" vid="password" v-slot="{ errors }">
            <v-text-field
              label="Neues Passwort"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider name="Passwort bestätigen" rules="required|confirmed:password" v-slot="{ errors }">
            <v-text-field label="Neues Passwort bestätigen" type="password" v-model="confirmPassword" :error-messages="errors" />
          </ValidationProvider>
        </v-card-text>
        <v-card-text class="py-0 px-3" v-else>
          <p>Du kannst nun mit deinem neuen Passwort wieder auf dein Konto zugreifen.</p>
        </v-card-text>
        <v-card-actions class="pt-0 pb-3 px-3">
          <v-btn type="submit" block rounded large color="primary" :loading="loading" :disabled="loading" v-if="!success">Speichern</v-btn>
          <v-btn to="/auth/login" color="primary" v-else>Ab zum Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ResetPassword',
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    password: '',
    confirmPassword: '',
    showPassword: false,
    success: false,
  }),

  computed: {
    ...mapGetters({
      loading: 'loading/get',
      error: 'error/get',
    }),
  },

  methods: {
    onSubmit() {
      this.$store.dispatch('auth/resetPassword', { code: this.$route.query.oobCode, password: this.password }).then(() => {
        if (!this.error) {
          this.success = true;
        }
      });
    },
  },

  destroyed() {
    this.$store.commit('error/clear');
  },
};
</script>
